import ApiService from "./api.service";

export function heartbeat() {
  return new Promise((resolve, reject) => {
    ApiService.post("/h5/user/heartbeat")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function unbindPhone() {
  return new Promise((resolve, reject) => {
    ApiService.post("/h5/user/unbindPhone")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 将扫码登录的结果推送到服务端
export function pushScanResult(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/auth/pushScanResult", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
