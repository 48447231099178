<template>
  <div class="page">
    <van-loading v-if="stateRef.loading" class="mt-5" color="#1989fa" size="36" vertical />
    <template v-else-if="stateRef.error">
      <van-empty image="network" :description="stateRef.error_text" />
      <div v-if="stateRef.is_reg == 0" class="px-5">
        <van-button type="primary" block @click="
          $router.push({
            name: 'Login',
            query: {
              code: 'scan',
              state: 'h5',
            } /* 传入 query 参数, 避免登陆页重新拉取授权 */,
          })
        ">立即注册</van-button>
      </div>
    </template>
    <div v-else>
      <div class="mt-5 text-center">
        <van-image round width="64" height="64" :src="stateRef.avatar" />
        <p class="text-secondary">{{ stateRef.nickname }}</p>
        <p class="text-secondary">
          {{ stateRef.login_msg ? stateRef.login_msg : "您正在登录" }}
        </p>
        <p class="text-primary">{{ stateRef.scope }}</p>
        <div v-if="!stateRef.login_msg">
          <div class="px-5 pt-3">
            <van-button type="primary" block plain @click="pushLoginStatus(2)">确认登录</van-button>
            <van-button type="danger" class="mt-3" block plain @click="pushLoginStatus(3)">取消登录</van-button>
          </div>
        </div>
        <div v-else>
          <van-icon :name="stateRef.login_ico" :class="stateRef.login_cls" size="60" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { Toast, Empty, Image as VanImage } from "vant";
import { pushScanResult } from "@/api/account.service";
import { onMounted, reactive } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { getAuthUrl, wechatAuth } from "@/api/wechat.service";
import { signaturePack } from "@/api/wechat.service";
import { isWechat, hideWechatOptionMenu } from "@/utils/util";

export default {
  components: {
    [Empty.name]: Empty,
    [VanImage.name]: VanImage,
  },
  setup() {
    const route = useRoute();
    const stateRef = reactive({
      loading: true,
      error: false,
      error_text: "",
      login_msg: "",
      login_ico: "",
      nickname: "",
      avatar: "",
      login_cls: "",
      type: "",
      scope: "",
      session_id: "",
      is_reg: 1,
    });

    const stopProcess = (message) => {
      stateRef.loading = false;
      stateRef.error = true;
      stateRef.error_text = message;
      Toast({ message, position: "bottom" });
    };

    // 更新联合登录Session记录状态为已扫码
    const updateUnionSession = async (data) => {
      try {
        await pushScanResult(data);
        const callback_url = location.href.split("#")[0];
        const redirect_url = getAuthUrl(callback_url);
        window.location.href = redirect_url; // 跳转去微信侧授权登录, 完成后返回本页面, 带参数 code 和 state
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        if (!msg) console.log(error);
        const message = msg ? msg : "前端脚本异常";
        stopProcess(message);
      }
    };

    // 推送用户操作结果
    const pushLoginStatus = async (status) => {
      try {
        await pushScanResult({
          session_id: stateRef.session_id,
          status,
        });
        if (status == 2) {
          stateRef.login_msg = "您已登录";
          stateRef.login_ico = "passed";
          stateRef.login_cls = "text-success";
        } else if (status == 3) {
          stateRef.login_msg = "您已取消登录";
          stateRef.login_ico = "close";
          stateRef.login_cls = "text-danger";
        } else {
          stopProcess("未知登录状态");
        }
        setTimeout(() => {
          wx.closeWindow();
        }, 10000);
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        if (!msg) console.log(error);
        const message = msg ? msg : "前端脚本异常";
        Toast({ message, position: "bottom" });
      }
    };

    // 微信授权登录成功后展示操作表单
    const preauth = async (queryCode, queryState, sessionId) => {
      try {
        const { result } = await wechatAuth(queryCode, queryState, sessionId);
        stateRef.avatar = result.avatar;
        stateRef.nickname = result.nickname;
        stateRef.is_reg = result.is_reg;
        stateRef.loading = false;

        if (stateRef.is_reg == 0) {
          // 存储授权信息, 避免在登陆页面重新从微信侧拉取一次信息
          window.sessionStorage.setItem("preauth", JSON.stringify(result));
          return stopProcess("请先注册, 然后重新扫码登陆");
        }

        const { type } = route.query;
        if (type == "console" && !result.is_admin) {
          stopProcess("您没有权限登录控制台");
          pushLoginStatus(3);
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        stopProcess(msg ? msg : "获取微信授权失败");
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const signaturePage = async () => {
      try {
        const { result } = await signaturePack(location.href.split("#")[0]);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ["hideOptionMenu", "closeWindow"],
        });
        wx.ready(() => {
          wx.hideOptionMenu();
        });
        wx.error((res) => {
          Toast(res.errMsg);
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({
          message: msg ? msg : "前端脚本异常/页面签名失败",
          position: "bottom",
        });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      if (!isWechat()) {
        return stopProcess("请使用微信扫码");
      }
      hideWechatOptionMenu();
      const { type, scope, session_id, code, state } = route.query;
      if (type != "portal" && type != "console") {
        return stopProcess("来路无效");
      }
      // 如以后需多个站点, 则设置 scope 的白名单列表
      if (!scope || process.env.VUE_APP_SCOPE_WHITELIST.indexOf(scope) == -1) {
        return stopProcess("Scope无效");
      }
      if (!session_id) {
        return stopProcess("二维码无效");
      }

      stateRef.type = type;
      stateRef.scope = scope;
      stateRef.session_id = session_id;

      if (code && state) {
        signaturePage();
        return preauth(code, state, session_id);
      }

      setTimeout(() => {
        updateUnionSession({ session_id, status: 1 });
      }, 500);
    });

    return { stateRef, pushLoginStatus };
  },
};
</script>
